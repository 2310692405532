@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');

@font-face {
	font-family: 'black_diamonds';
	src: url('../../../fonts/BlackDiamondsPersonalUse.eot');
	src: url('../../../fonts/BlackDiamondsPersonalUse.eot?#iefix') format('embedded-opentype'),
		url('../../../fonts/BlackDiamondsPersonalUse.woff2') format('woff2'),
		url('../../../fonts/BlackDiamondsPersonalUse.woff') format('woff'),
		url('../../../fonts/BlackDiamondsPersonalUse.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('../../../fonts/Gotham-Bold.eot');
	src: url('../../../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../../fonts/Gotham-Bold.woff') format('woff'),
		url('../../../fonts/Gotham-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'myriad';
	src: url('../../../fonts/MyriadPro-Regular.eot');
	src: url('../../../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../../fonts/MyriadPro-Regular.woff') format('woff'),
		url('../../../fonts/MyriadPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
